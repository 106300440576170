import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

import Header from "../components/Header";
import { SmallContainer } from "../components/Grid";
import Box from "../components/Box";

const BackgroundBox = styled(Box)`
  background-color: #4844a3;
  min-height: 100vh;
  color: white;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
`;

const CompletedMessage = styled.p`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  text-align: center;
  padding: 40px;
  margin: auto 5%;
  background-color: rgba(50, 194, 162, 0.4);
`;

const AccountPage = ({ intl }) => (
  <BackgroundBox>
    <Header
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: "globals.courseOverview" }),
          path: "/lt"
        },
        {
          title: intl.formatMessage({ id: "emailVerification.verified" }),
          path: "/lt/signup"
        }
      ]}
    />
    <SmallContainer pt={[6]}>
      <CompletedMessage>
        <FormattedMessage id="emailVerification.completedMessage" />
      </CompletedMessage>
    </SmallContainer>
  </BackgroundBox>
);

export default injectIntl(AccountPage);
